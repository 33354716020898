<template>
    <div class="w-100 m-b-32">
        <div class="p-b-16 f-16 f-b">
            <span>{{ options.title }}</span>
        </div>
        <div v-if="!options.props" flex="dir:left main:left cross:top" class="f-14" style="line-height: 30px; gap: 60px; flex-wrap: wrap">
            <div v-for="(item, index) in options.content" :key="index" flex="dir:left main:left cross:top">
                <div>
                    <span class="text-right">{{ item.title }}</span>
                </div>
                <div>
                    <div v-if="item.chat && item.img" class="p-4 b-1 brd-4">
                        <el-image style="width: 100px; height: 100px" :src="item.chat" :preview-src-list="[item.chat]" preview-teleported fit="contain" />
                    </div>
                    <div v-else-if="item.chatMore && item.img" class="w-px-300 p-4 b-1 brd-4 more-images-box" flex>
                        <div v-for="(jtem, jndex) in item.chatMore" :key="jndex">
                            <el-image style="width: 100px; height: 100px" :src="jtem" :preview-src-list="[jtem]" preview-teleported fit="contain" />
                        </div>
                    </div>
                    <div v-else-if="item.video && item.chat" class="p-4 b-1 brd-4">
                        <video :src="item.chat" controls width="100" height="100"></video>
                    </div>
                    <div v-else-if="item.time && item.chat">
                        <span>{{ util.format(item.chat) }}</span>
                    </div>
                    <div v-else-if="item.tag && item.chat">
                        <el-tag :type="item.tag.props && item.tag.props.type ? item.tag.props.type : 'primary'">{{ item.chat }}</el-tag>
                    </div>
                    <div v-else>
                        <span>{{ item.chat }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div v-else-if="options.props && options.props.table">
            <el-table :data="options.content" border :header-cell-style="{ background: '#F5F7FA', 'font-weight': 'bold', color: '#666' }">
                <el-table-column v-for="(item, index) in options.props.title" :key="index" :label="item" :prop="index" :min-width="100">
                    <template #default="scope" v-if="Object.keys(options.props.mate).includes(index)">
                        <div v-if="options.props.mate[index] == 'img'">
                            <el-image style="width: 100px; height: 100px" :src="scope.row[index]" :preview-src-list="[scope.row[index]]" preview-teleported fit="contain" />
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
import { util } from '@/assets/js'
export default {
    props: ['options'],
    setup() {
        return {
            util
        }
    }
}
</script>
<style lang="scss" scoped>
.more-images-box {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}
</style>
