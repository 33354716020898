<template>
    <div v-for="(item, index) in this.model_info" :key="index">
        <InfoModel :options="item" />
    </div>
</template>
<script>
import { ref } from 'vue'
import { http } from '@/assets/js'
import InfoModel from './InfoModel.vue'

export default {
    name: 'OrderInfo',
    inject: ['reload'],
    props: { modelInfo: { type: Object, required: true } },
    watch: {
        modelInfo: {
            handler(newVal) {
                this.model_info = newVal
            },
            immediate: true,
            deep: true
        }
    },
    data() {
        return {
            model_info: {}
        }
    },
    components: {
        InfoModel
    }
}
</script>
<style lang="scss" scoped></style>
